<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="报装来源" class="lab">
              <el-select v-model="search.sourceType" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="报装状态" class="lab">
              <el-select v-model="search.status" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="用气类型" class="lab">
              <el-select v-model="search.type" clearable placeholder="请选择" @change="typyChange" class="SearchOptions">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="用途" class="lab">
              <el-select v-model="search.useDesc" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in useList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="" class="lab">
              <el-select v-model="search.timeType"  placeholder="请选择" class="SearchOptions2">
                <el-option
                v-for="item in dateTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item>
               <el-date-picker
                class="SearchDate"
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <span style="float:right;width:150px">
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
          </el-form>
        </div>
        <div class="table">
          <div class="table-height">
           <div>
                <span  style="font-size:18px;">报装申请-公司</span>
                <span style="float:right"> <el-button type="primary"  @click="showadd" size="small" plain>新增</el-button></span>
            </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            :stripe="true"
            @sort-change="sort_change"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="sourceTypeName"
              label="报装来源"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop=""
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="typeName"
              label="用气类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="intime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
             
            >
            </el-table-column>
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
              show-overflow-tooltip
             
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.status==0" type="warning" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==1" type="primary" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==2" type="info" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==3" type="success" size="small" plain>{{row.statusName}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                   <el-button
                    type="text"
                    size="small"
                    style="color:#008000"
                    @click="edit(row,1)" v-if="row.status==0"
                    >受理</el-button>
                      <el-button v-if="row.status==1"
                    type="text"
                    size="small" 
                    style="color:#F56C6C"
                    @click="edit(row,3)"
                    >取消</el-button>
                    <el-button
                    type="text"
                    size="small" @click="desc(row,2)" v-if="(row.status==1||row.status==2||row.status==3)"
                    >处理详情</el-button>
                  
                    <el-button v-if="row.status==1"
                    type="text"
                    size="small" 
                    style="color:#e6a23c"
                   @click="edit(row,2)"
                    >完结</el-button>
              </template>
            </el-table-column>
          </el-table>
          
        </div>
        <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div></div>
    <el-dialog
      title="报装人信息"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      @close="clearnF('form')">
      <el-form ref="addform" :model="form" label-width="130px" :rules="rule" size="small">
        <div>
          <el-row>
            <el-col :span="8">
          <el-form-item label="用气类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" @change="selectType"   style="width:173px">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </el-col>
           <el-col :span="8" >
          <el-form-item :label="form.type==1?'法人姓名':'产权人姓名'"  prop="name" name="limitLength" maxlength="100">
            <el-input v-model="form.name" :placeholder="form.type==1?'请输入法人姓名':'请输入产权人姓名'"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
           <el-col :span="8"  >
          <el-form-item :label="form.type==1?'法人身份证号':'身份证号'" prop="idcard">
            <el-input v-model="form.idcard" :placeholder="form.type==1?'请输入法人身份证号':'请输入身份证号'"   name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="8"  v-if="form.type==1">
          <el-form-item label="公司名称" prop="corporation" name="limitLength" maxlength="100">
            <el-input v-model="form.corporation" placeholder="请输入公司名称"   name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="16">
          <el-form-item :label="form.type==1?'公司地址':'地址'" prop="address" name="limitLength" maxlength="100">
            <el-input v-model="form.address" :placeholder="form.type==1?'请输入公司地址':'请输入地址'"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
          <el-form-item label="联系人电话" prop="tel">
            <el-input v-model="form.tel" placeholder="联系人电话"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item label="用途" prop="useDesc">
            <el-select v-model="form.useDesc"  placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in addUseList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          
          </el-row>
          <el-row>
           <el-col :span="24">
          <el-form-item :label="form.type==1?'法人身份证':'产权人身份证'" prop="endDate">
            <div style="display:flex;width:400px">
               <div style="flex:1;overflow:hidden;height:125px;width:190px;">
            <el-upload
                ref="upload1"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun1"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg1"
                :file-list="repairBeforFilefileList1"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="1"
                 :on-exceed="msgLimit"
                >
                <img src="../../assets/image/upload1.png" style="" class="uploadImg1">
                
            </el-upload>
               </div>
                <div style="flex:1;overflow:hidden;height:125px;width:190px;">
            <el-upload
                ref="upload2"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun2"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg2"
                :file-list="repairBeforFilefileList2"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="1"
                 :on-exceed="msgLimit"
                >
                <img src="../../assets/image/upload2.png" class="uploadImg1">
            </el-upload>
                </div>
            </div>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
          <el-form-item :label="form.type==1?'营业执照':'房产证(购房合同)'" prop="urlList">
            
            <el-upload
                ref="upload"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun3"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove3"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg3"
                :file-list="repairBeforFilefileList3"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="10"
                 :on-exceed="msgLimit"
                >
               <img src="../../assets/image/upload3.png" class="uploadImg1">
               
                
            </el-upload>

            
          </el-form-item>
         </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
    <div><img :src="dialogImageUrl" width="100%"/></div>
    </el-dialog>
    <applyEdit :id="id" :type="type" ref="applyEdit"></applyEdit>
    <applyDesc  :id="id" :type="type" ref="applyDesc"></applyDesc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzApply_pageCompany,bzApply_save,getDateTime} from '../../RequestPort/report/reportApply'
import applyEdit from './applyEdit.vue'
import applyDesc from './applyDesc.vue'
import {uploadImg} from "../../RequestPort/apply/apply.js"
export default {
  name: 'reportApply',
  components: {applyEdit,applyDesc},
  mixins: [],
  data() {
    return {
      sourceList:[{ label: '公众号', value: '3' },{ label: '官网', value: '4' },{ label: '客服提交', value: '5' }],
      statusList:[{ label: '已申报', value: '0' },{ label: '受理中', value: '1' },{ label: '已取消', value: '2' },{ label: '已办结', value: '3' }],
      typeList:[{label:'工商户（企业客户）',value:'1'},{label:'个人户（居民客户）',value:'2'}],
      useList:[],
      dateTypeList:[{ label: '申请日期', value: '1' },{ label: '受理日期', value: '2' },{ label: '取消日期', value: '3' },{ label: '完结日期', value: '4' }],
      search: { current: 1, size: 100,timeType:'1'}, //搜索
      searchDatatime:[],
      total: 10,
      tableData: [{enterWarehouseName:1},], //列表对象
      id:'',
      type:'',
      dialogVisible:false,
      form:{},
      addUseList:[],
      rule:{
        type:{type:'string',required: true,message: '请选择用气类型',trigger: ['blur', 'change']},
        name:{type:'string',required: true,message: '请输入姓名',trigger: ['blur', 'change']},
			 idcard:{type:'string',required: true,message: '请输入正确的身份证号',trigger: ['blur', 'change'],pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/},
			 corporation:{type:'string',required: true,message: '请输入公司名称',trigger: ['blur', 'change']},
			 address:{type:'string',required: true,message: '请输入地址',trigger: ['blur', 'change']},
			 tel:{type:'string',required: true,message: '请输入正确的联系人电话',pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,trigger: ['blur', 'change']},
			 useDesc:{required: true,message: '请选择用途',trigger: ['blur', 'change']}
       },
      fileList1:[],
      fileList2:[],
      fileList3:[],
      urlList1:[],
      urlList2:[],
      urlList3:[],
      upload1Add:true,
      upload2Add:true,
      repairBeforFilefileList1:[],
      repairBeforFilefileList2:[],
      repairBeforFilefileList3:[],
      repairBeforFileFormData: new FormData(),
      dialogImageUrl:'',
      dialogVisibleImg:false
    }
  },
  mounted() {
    this.searchDatatime = getDateTime();
    this.getOption();
    this.getList();
  },
  methods: {
      getOption(type){
        if(!this.search.type&&!type){
          selectDictListByParentCode({parentCode:'70001'}).then(res=>{
          if(res.code==200){
            var data1= res.data
             selectDictListByParentCode({parentCode:'70000'}).then(res=>{
              if(res.code==200){
                var data2 = res.data
                this.useList = data1.concat(data2)
              }
            })
          }
        })
        }else{
          if(type){
            var code = type==1?'70001':'70000';
          }else{
            var code = this.search.type==1?'70001':'70000';
          }
          selectDictListByParentCode({parentCode:code}).then(res=>{
            if(res.code==200){
              this.useList = res.data
              this.addUseList = res.data
            }
          })
        }
        
      },
      typyChange(val){
        this.getOption();
      },
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        timeType:'1'
      }),
      this.useList =this.getOption();
      this.searchDatatime = getDateTime();
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      bzApply_pageCompany(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    sort_change(column){
      this.search.sortColumn=column.prop;
      if(column.order=='ascending'){
        this.search.sortKey =0
      }else{
        this.search.sortKey =1
      }
      this.getList(1)
    },

    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    edit(row,type){
      this.id = row.id+"";
      this.type = type+""
      this.$refs.applyEdit.setdialogVisible(true)
    },
    desc(row,type){
      this.id = row.id+"";
      this.type = type+""
      this.$refs.applyDesc.setdialogVisible(true)
    },
    
    async repairBeforUploadFun1(e){
      
      this.repairBeforFileFormData.append('file',e.file)
     await uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList1.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
    async  repairBeforUploadFun2(e){
      this.repairBeforFileFormData.append('file',e.file)
    await  uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList2.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
    async  repairBeforUploadFun3(e){
      this.repairBeforFileFormData.append('file',e.file)
     await uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList3.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
      beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg1(file, fileList) {
      this.upload1Add = false;
      this.repairBeforFilefileList1 = fileList
    },
    beforHFhandleChangeImg2(file, fileList) {
      this.upload2Add = false;
      this.repairBeforFilefileList2 = fileList
    },
    beforHFhandleChangeImg3(file, fileList) {
      this.repairBeforFilefileList3 = fileList
    },
    handleRemove1(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList1,file);
    this.urlList1.splice(index,1)
    setTimeout(() => {
      this.upload1Add = true
    }, 1000)
    
    },
    handleRemove2(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList2,file);
    this.urlList2.splice(index,1)
    setTimeout(() => {
      this.upload2Add = true
    }, 1000)
     
    },
    handleRemove3(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList3,file);
    this.urlList3.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    msgLimit(file,fileList){
      this.$message.error("最多上传10张图片");
    },
    selectType(val){
      this.getOption(val)
    },
    clearnF(){
      this.form={
        type:'1',
        address:'',
        contractImageList:[],
        corporation:'',
        idcard:'',
        idcard1Image:'',
        idcard2Image:'',
        name:'',
        tel:'',
        type:'',
        useDesc:''
      }
      this.urlList1 = [];
      this.urlList2 = [];
      this.urlList3 = [];
      if(this.$refs.addform){
        this.$refs['addform'].resetFields();
      }
    },
    showadd(){
      this.form.type='1'
      this.getOption(this.form.type);
      this.dialogVisible = true;
    },
    saveadd(){
       this.$refs['addform'].validate(valid => {
            if (valid) {
              if(this.urlList1.length>0){
                  this.form.idcard1Image = this.urlList1[0]
              }else{
                this.form.idcard1Image =''
              }
              if(this.urlList2.length>0){
                  this.form.idcard2Image = this.urlList2[0]
              }else{
                this.form.idcard2Image =''
              }
              if(this.urlList3.length>0){
                 this.form.contractImageList = this.urlList3;
              }else{
                this.form.contractImageList = [];
              }
              this.form.sourceType = 5
              bzApply_save(this.form).then(res=>{
                this.$message.success('添加成功！')
                this.dialogVisible = false;
                this.getList();
                this.clearnF();
              })
              
            }
       })
    }
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{height:125px;width:190px;line-height:125px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{height:125px;width:190px;line-height:125px}
::v-deep .el-upload--picture-card {border: none;}
.uploadImg1{height:125px;width:190px}
.uploadImg2{height:125px;width:190px;margin-left: 200px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   height: auto;
}
.all {
  background-color:#f3f4f8;height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
   height: calc(100vh - 70px);
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
